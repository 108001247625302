import { Dispatch, FC, SetStateAction } from "react";
import { Label } from "elements/Input";
import Link from "next/link";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import CrossReverse from "public/cross-reverse.svg";

type PropertyRange = "1 - 4" | "5 - 19" | "20+";

export const InvestorContactModal: FC<{
	setShowCommercialContactModal: Dispatch<SetStateAction<boolean>>;
}> = ({ setShowCommercialContactModal }) => {
	const trackEvent = useEventTracker();

	const handleNumOfPropertiesClick = ({
		numOfProps,
	}: {
		numOfProps: PropertyRange;
	}) => {
		trackEvent({
			eventName: constants.EVENTS.Commerical_GetStarted_NumOfPropsModal,
			data: { "# Of Props Choice": numOfProps },
		});
	};

	const renderCommercialContactModalOption = (
		propertyRange: string,
		href: string
	) => {
		const onClick = () => {
			handleNumOfPropertiesClick({
				numOfProps: propertyRange as PropertyRange,
			});
			if (href.startsWith("/appeal/portfolio")) {
				trackEvent({
					eventName: constants.EVENTS.Investor_page_address_page,
				});
			}
			setShowCommercialContactModal(false);
		};

		return (
			<Link
				className="landing-commercial-contact-modal-option"
				href={href}
				onClick={onClick}>
				<p className="lg">{propertyRange}</p>
				<Label>Properties</Label>
			</Link>
		);
	};

	return (
		<div
			id="landing-commercial-contact-modal-underlay-container"
			onClick={() => setShowCommercialContactModal(false)}>
			<div
				id="landing-commercial-contact-modal"
				onClick={e => e.stopPropagation()}>
				<div id="landing-commercial-contact-modal-inner">
					<div
						id="landing-commercial-contact-modal-close"
						onClick={() => setShowCommercialContactModal(false)}>
						<CrossReverse />
					</div>
					<p className="lg">
						<b>How many properties are you interested in signing up?</b>
					</p>
					<div id="landing-commercial-contact-modal-option-container">
						{renderCommercialContactModalOption(
							"1 - 4",
							"/appeal/portfolio?landing_page_source=investors"
						)}
						{renderCommercialContactModalOption(
							"5 - 19",
							"/appeal/portfolio?landing_page_source=investors"
						)}
						{renderCommercialContactModalOption(
							"20+",
							"/investors#contact-section"
						)}
					</div>
				</div>
			</div>
			<div id="landing-commercial-contact-modal-underlay">
				<style jsx global>
					{`
						body {
							overflow: hidden;
						}
					`}
				</style>
			</div>
		</div>
	);
};
