import {
	Dispatch,
	FC,
	ReactElement,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import ExpandIcon from "public/expand-circle.svg";
import useOutsideClick from "utils/useOutsideClick";
import Grid from "./Grid";

export interface IFaqCardProps {
	title: string;
	body: string | ReactElement;
	id?: string;
	isExpanded?: boolean;
	setViewedFaqs?: Dispatch<SetStateAction<string[]>>;
	Icon?: JSX.Element | ReactElement | null | undefined;
}

const FaqCard: FC<IFaqCardProps> = ({
	title,
	body,
	id,
	isExpanded = false,
	setViewedFaqs,
	Icon,
}) => {
	const [expanded, setExpanded] = useState(isExpanded);

	useEffect(() => {
		if (expanded && setViewedFaqs) {
			setViewedFaqs(prev => {
				if (prev.includes(title)) {
					return prev;
				} else {
					return [...prev, title];
				}
			});
		}
	}, [expanded, setViewedFaqs, title]);

	return (
		<div id={id} className={"pricing-faq-card" + (expanded ? "-open" : "")}>
			<div
				className="pricing-faq-card-title-section"
				onClick={() => setExpanded(p => !p)}>
				<div>
					<ExpandIcon
						height="16px"
						width="16px"
						viewBox="0 0 16 16"
						className={`pricing-faq-card-${expanded ? "close" : "expand"}`}
					/>
				</div>
				<div>
					<p className="lg">{title}</p>
				</div>
				{Icon ? (
					<div className="pricing-faq-card-right-icon">{Icon}</div>
				) : null}
			</div>

			{expanded && (
				<div className="pricing-faq-card-body-section">
					<div className="pricing-faq-card-body">
						{typeof body === "string" ? <p>{body}</p> : body}
					</div>
				</div>
			)}
		</div>
	);
};

export const FaqCardCols: FC<{
	faqs: IFaqCardProps[];
	setViewedFaqs?: Dispatch<SetStateAction<string[]>>;
}> = ({ faqs, setViewedFaqs }) => {
	return (
		<>
			<div className="pricing-faq-card-col">
				{faqs.slice(0, Math.ceil(faqs.length / 2)).map(p => (
					<FaqCard {...p} key={p.title} setViewedFaqs={setViewedFaqs} />
				))}
			</div>
			<div className="pricing-faq-card-col">
				{faqs.slice(Math.ceil(faqs.length / 2), faqs.length).map(p => (
					<FaqCard {...p} key={p.title} setViewedFaqs={setViewedFaqs} />
				))}
			</div>
		</>
	);
};

export const FaqCard2: FC<IFaqCardProps> = ({
	title,
	body,
	id,
	isExpanded = false,

	Icon,
}) => {
	const [expanded, setExpanded] = useState(isExpanded);

	const ref = useOutsideClick(() => {
		setExpanded(false);
	});

	return (
		<div
			ref={ref}
			id={id}
			className={"pricing-faq-card" + (expanded ? "-open" : "")}
			onClick={() => {
				setExpanded(p => !p);
			}}>
			<div className="pricing-faq-card-title-section">
				<div>{Icon ? Icon : null}</div>
				<div>
					<p className="lg">{title}</p>
				</div>
			</div>
			<div className="pricing-faq-card-body-section">
				<div className="pricing-faq-card-body">
					{typeof body === "string" ? <p>{body}</p> : body}
				</div>
			</div>
		</div>
	);
};

export const FaqCard2Cols: FC<{
	faqs: IFaqCardProps[];
	setViewedFaqs?: Dispatch<SetStateAction<string[]>>;
}> = ({ faqs, setViewedFaqs }) => {
	return (
		<>
			<div className="pricing-faq-card-col">
				{faqs.slice(0, Math.ceil(faqs.length / 2)).map(p => (
					<FaqCard2 {...p} key={p.title} setViewedFaqs={setViewedFaqs} />
				))}
			</div>
			<div className="pricing-faq-card-col">
				{faqs.slice(Math.ceil(faqs.length / 2), faqs.length).map(p => (
					<FaqCard2 {...p} key={p.title} setViewedFaqs={setViewedFaqs} />
				))}
			</div>
		</>
	);
};

export default FaqCard;
